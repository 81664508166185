import React from 'react'
import Layout from '../components/layout'

import {
  Wrapper,
  Heading,
  Spacer,
} from '../components/atoms'

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <Spacer>
        <Heading>404</Heading>
      </Spacer>      
    </Wrapper>
  </Layout>
)

export default NotFoundPage
